export const options = [
  {
    label: 'Id',
    prop: 'id'
  },
  {
    label: '托管套餐名称',
    prop: 'name'
  },
  {
    label: '类型',
    prop: 'investment_type'
  },
  {
    label: '描述',
    prop: 'description'
  },
  {
    label: '持续天数',
    prop: 'duration'
  },
  {
    label: '总利率',
    prop: 'interest_rate'
  },
  {
    label: '创建时间',
    prop: 'CreatedAt'
  },
  {
    label: '操作',
    prop: 'action',
    width: 160
  }
]
